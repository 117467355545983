import React from 'react'
import { IndexPage } from './index'
import { useStaticQuery, graphql } from "gatsby"




// const checkoutUrls = []

const Page = (props) => {
  const {
    usd // Us pricing configuration
   } = useStaticQuery(query)
    .site
      .siteMetadata
        .pricingConfiguration

  // const { ebook, complete, video } = us

  return (
    <>
      <IndexPage
        location={props.location}
        checkoutUrls={usd}
      />
    </>
  )
}

const query = graphql`
query UsPage {
  site {
    siteMetadata {
      pricingConfiguration {
        usd {
          ebook {
            price
            oldPrice
            url
          }
          complete {
            price
            oldPrice
            url
          }
          video {
            price
            oldPrice
            url
          }
          free {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
}
`


export default Page
